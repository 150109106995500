import { useCallback, useEffect } from "react";
import { useDispatch } from "react-redux";
import { useWallet } from "@solana/wallet-adapter-react";
import { useWalletModal } from "@solana/wallet-adapter-react-ui";
import { setConnectingWallet } from "../../../Redux/account/Actions/accountActions";
import { finishAuth } from "../../../Redux/user/Actions/userActions";
import { useWalletConnectButton } from "@solana/wallet-adapter-base-ui";
import { Buffer } from "buffer";

export const useSolana = () => {
  const dispatch = useDispatch();
  const modal = useWalletModal();
  const { walletName } = useWalletConnectButton();

  const solanaWallet = useWallet();

  useEffect(() => {
    const connect = async () => {
      if (walletName) {
        solanaWallet.select(walletName);
        await solanaWallet.connect();
      }
      if (solanaWallet.connected && solanaWallet.publicKey) {
        dispatch(setConnectingWallet("solana"));
        const signFunc = async (message: string, account: string): Promise<unknown> => {
          console.log("account", account);
          if (!solanaWallet.signMessage) {
            throw new Error("signMessage function is undefined");
          }
          const messageUint8Array = Uint8Array.from(Buffer.from(message, "utf8"));
          // const messageUint8Array = new TextEncoder().encode(message);
          console.log("messageUint8Array", messageUint8Array);
          console.log("message", message);
          const signature = await solanaWallet.signMessage(messageUint8Array);
          return Buffer.from(signature).toString("hex");
        };
        dispatch(
          finishAuth({
            signFunc: signFunc,
            account: solanaWallet.publicKey?.toString(),
            walletName: "solana",
          })
        );
      }
    };
    connect();
  }, [walletName, solanaWallet, dispatch]);

  return useCallback(async () => {
    modal.setVisible(true);
  }, [modal]);
};
